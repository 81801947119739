<!--eslint-disable vue/require-v-for-key-->
<!-- /* eslint-disable no-unused-vars */ -->
<!-- /* eslint-disable vue/this-in-template */ -->
<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                <span v-if="theTotalProducts===1000"> over </span>
                <span v-if="theTotalProducts=== 1 "> 1 result found </span>
                <span v-if="theTotalProducts > 1 "> {{ theTotalProducts }} results found </span>
                <span v-if="theTotalProducts=== 0 "> no results found </span>

              </div>
            </div>

            <div class="view-options d-flex">

              <!-- Sort Button -->
              <!-- ToDo: If replica index naming conventions change then the price asc and price desc index name below need to be changed.  -->
              <ais-sort-by
                :items="[
                  { value: algoliaIndexName, label: 'Featured' },
                  { value: 'price_asc_vindex', label: 'Price asc.' },
                  { value: 'price_desc_vindex', label: 'Price desc.' },
                ]"
              > -
                <div slot-scope="{ items, refine, currentRefinement}">

                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="items.find(i => i.value === currentRefinement).label"
                    right
                    variant="outline-primary"
                  >

                    <b-dropdown-item
                      v-for="item in items"
                      :key="item.value"
                      @click="refine(item.value)"
                    >
                      {{ item.label }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </ais-sort-by>

              <ais-hits-per-page
                :items="[
                  { label: '9 per page', value: 9, default: true, isRefined: true },
                  { label: '18 per page', value: 18 },
                ]"
              >
                <div slot-scope="{ items, refine}">

                  <!-- Hits per page Button -->
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="hitsPerPageOption.label || items[0].label"
                    right
                    variant="outline-primary"
                  >
                    <b-dropdown-item
                      v-for="item in items"
                      :key="item.value"
                      @click.prevent="refine(item.value);hitsPerPageOption=item"
                    >
                      {{ item.label }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </ais-hits-per-page>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>

          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <!-- <ais-search-box
      show-loading-indicator
    >
      <div
        slot-scope="{ currentRefinement, refine }"
        class="ecommerce-searchbar mt-1"
      >
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input
                show-loading-indicator

                type="search"
                :value="currentRefinement"
                class="search-product"
                placeholder="Search Locations, Developments or Lots"
                @input="refine($event)"
              />

              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>

          </b-col>
        </b-row>
      </div>
    </ais-search-box> -->

    <!-- <ais-hits>
      <template #default="results">
        <p> {{ results }}
        </p>
      </template>
    </ais-hits>
    <ais-state-results>
      <template
        #default="{ state }"
      >
        <div
          style="font-family:Consolas"
        >
          {{
            state
          }}
        </div></template>
    </ais-state-results> -->
    <!-- <ais-hierarchical-menu :attributes="['project.geohash']"> -->
    <!-- .. -->
    <!-- </ais-hierarchical-menu> -->
    <!-- <ais-refinement-list attribute="project.geohash.hash3">
      <div slot-scope="{ items }">
        {{ items }}
      </div>

    </ais-refinement-list> -->
    <!-- Currrent Refinements -->
    <ais-current-refinements>
      <div slot-scope="{ items, refine}">

        <b-form-group>
          <!-- Current Refinments for Price -->
          <!--eslint-disable-next-line vue/require-v-for-key-->
          <div
            v-for="item in items"
          >

            <div
              v-if="item.attribute === 'price'"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mt-1"
                variant="primary"
                size="sm"
                @click="clearRefinementForSliders(item.refinements, refine)"
              >

                Price: {{ displayRefinementsForPrice(item) }}
                <feather-icon
                  class="ml-1"
                  icon="XIcon"
                />

              </b-button>
            </div>
            <!-- Current Refinments For Frontage -->
            <div
              v-if="item.attribute === 'frontage'"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mt-1"
                variant="primary"
                size="sm"
                @click="clearRefinementForSliders(item.refinements, refine)"
              >
                Frontage: {{ displayRefinementsForFrontageDepthAndSize(item) }}
                <feather-icon
                  class="ml-1"
                  icon="XIcon"
                />

              </b-button>
            </div>
            <!-- Current Refinments for Depth  -->
            <div
              v-if="item.attribute === 'depth'"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mt-1"
                variant="primary"
                size="sm"
                @click="clearRefinementForSliders(item.refinements, refine)"
              >
                Depth: {{ displayRefinementsForFrontageDepthAndSize(item) }}
                <feather-icon
                  class="ml-1"
                  icon="XIcon"
                />

              </b-button>
            </div>

            <!-- Current Refinments for Size  -->
            <div
              v-if="item.attribute === 'size'"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mt-1"
                variant="primary"
                size="sm"
                @click="clearRefinementForSliders(item.refinements, refine)"
              >
                Size: {{ displayRefinementsForFrontageDepthAndSize(item) }}
                <feather-icon
                  class="ml-1"
                  icon="XIcon"
                />

              </b-button>
            </div>
            <!-- Current Refinements for Projects -->
            <div
              v-if="item.label === 'project.name'"
            >
              <div
                v-for="project in item.refinements"
              >

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mt-1"
                  variant="primary"
                  size="sm"
                  @click="clearRefinementForProject(project, refine);updateProjectsSelected(project.label)"
                >

                  project: {{ project.label }}
                  <feather-icon
                    class="ml-1"
                    icon="XIcon"
                  />

                </b-button>
              </div>
            </div>

          </div>
          <!-- Current Refinements for Search -->

          <div
            v-if="placeSearched"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-1"
              variant="primary"
              size="sm"
              @click="clearRefinementForSerach();"
            >

              Location: {{ placeSearched.text }}
              <feather-icon
                class="ml-1"
                icon="XIcon"
              />

            </b-button>
          </div>

        </b-form-group>

      </div>
    </ais-current-refinements>

    <!-- Prodcuts -->
    <b-tabs
      v-model="activeTab"
      class="mt-1"
      align="right"
    >
      <b-tab>

        <template #title>
          <feather-icon icon="ListIcon" />
          <span>List</span>
        </template>

        <ais-hits>
          <section
            slot-scope="{ items }"
            :class="itemView"
          >

            <!-- {{ log(items.map(i => i.project.name)) }} -->

            <!-- Render map next to results for easy debugging -->
            <!-- <MglMap
              :access-token="accessToken"
              :map-style.sync="mapStyle"
              :center="initialCenter"
              :zoom="zoom"
              @mouseup="refineMap(state, $event)"
            >
              <MglNavigationControl
                position="top-right"
              />
              <MglGeolocateControl position="top-left" />
              <MglFullscreenControl position="bottom-left" />
              <MglScaleControl />
              <MglMarker
                v-for="item of items"
                :key="item.objectID"
                :title="item.name"
                :coordinates="items ? [item.project.longitude, item.project.latitude] : null"
                color="red"
              />
            </MglMap> -->
            <!-- {{ log(items) }}
            {{ showingMapMarkers(items) }} -->
            <b-card
              v-for="product in items"
              :key="product.id"
              class="ecommerce-card"
              no-body
            >

              <div class="item-img-modified text-center">
                <b-link :to="{ name: 'inventory-product-details', params: { slug: product.objectID, lotIMage: product.lotImage}}">

                  <!-- // eslint-disable-next-line vue/no-unused-vars -->

                  <b-img
                    v-if="!product.lotPlanUrl"
                    alt="Not Found"
                    center

                    height="200"
                    :src="require(`@/assets/images/lots/${product.lotImage}`)"
                  />

                  <b-img
                    v-if="product.lotPlanUrl"
                    alt="Not Found"
                    center
                    :class=" $store.state.appConfig.layout.skin === 'light' ? 'card-img-top colour-change-img-white': 'card-img-top colour-change-img-black'"
                    height="225"
                    :src="product.lotPlanUrl"
                  />

                </b-link>
              </div>
              <!-- Product Details -->
              <b-card-body>
                <div class="item-wrapper">
                  <div class="item-rating">

                    <h4>
                      <b-link
                        class="text-body"
                        :to="{ name: 'inventory-product-details', params: { slug: product.objectID } }"
                      >
                        Lot {{ product.name }}
                      </b-link>
                    </h4>

                  </div>
                  <div>
                    <h6 class="item-price">
                      <h4>
                        <b-link :to="{ name: 'inventory-product-details', params: { slug: product.objectID } }">
                          ${{ new Intl.NumberFormat( { style: 'currency', }).format(product.price) }}
                        </b-link>

                      </h4>
                    </h6>
                  </div>
                </div>

                <div class="item-wrapper">
                  <div class="item-rating">
                    <b-card-text class="item-description-modified">

                      <b-link class=" font-weight-bolder">
                        {{ product.project.name }}
                      </b-link> by {{ product.project.vendorName }}

                    </b-card-text>

                  </div>
                  <div>

                    <h6>
                      <h6>
                        <b-card-text>
                          {{ product.size }}m<sup>2</sup>
                        </b-card-text>

                      </h6>
                    </h6>

                  </div>

                </div>

                <b-card-text class="item-description">
                  <feather-icon
                    icon="MapPinIcon"
                    size="18"
                  />
                  Melbourne?
                </b-card-text>

              </b-card-body>

              <!-- Product Actions -->
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price">
                      <b-link :to="{ name: 'inventory-product-details', params: { slug: product.objectID } }">
                        ${{ new Intl.NumberFormat( { style: 'currency', }).format(product.price) }}
                      </b-link>
                    </h4>
                  </div>
                </div>
                <b-button
                  variant="light"
                  tag="a"
                  class="btn-wishlist"
                  @click="toggleProductInWishlist(product)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                    :class="{'text-danger': product.isInWishlist}"
                  />
                  <span>Watch</span>
                </b-button>
                <b-button
                  variant="primary"
                  tag="a"
                  class="btn-cart"
                  @click="handleCartActionClick(product)"
                >
                  <feather-icon
                    icon="CheckSquareIcon"
                    class="mr-50"
                  />
                  <span>{{ product.isInCart ? 'Complete Reservation' : 'Reserve' }}</span>
                </b-button>
              </div>
            </b-card>
          </section>
        </ais-hits>

        <!-- Pagination -->
        <ais-pagination>
          <section
            slot-scope="{
              currentRefinement,
              nbHits,
              refine,
            }"
          >

            <b-row>
              <b-col cols="12">

                <b-pagination
                  :value="currentRefinement+1"
                  :total-rows="setTheTotalProducts(nbHits)"
                  :per-page="hitsPerPageOption.value"

                  first-number
                  align="center"
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="refine($event-1)"
                >

                  <template
                    #prev-text
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </section>
        </ais-pagination>
      </b-tab>

      <!-- <b-tabs class="mt-1 nav nav-tabs justify-content-center"> -->
      <b-tab>

        <template
          #title
        >
          <feather-icon icon="TrashIcon" />
          <span>Old Map</span>
        </template>
        <ais-hits>
          <div
            slot-scope="{ items }"
          >
            <MglMap
              style="height:400px"
              :access-token="accessToken"
              :map-style.sync="mapStyle"
              :center="initialCenter"
              :zoom="zoom"
              @mouseup="refineMap(state, $event)"
              @load="onMapLoad($event)"
            >
              <MglNavigationControl
                position="top-right"
              />
              <MglGeolocateControl position="top-left" />
              <MglFullscreenControl position="bottom-left" />
              <MglScaleControl />
              <MglMarker
                v-for="item of items"
                :key="item.objectID"
                :title="item.name"
                :coordinates="items ? [item.project.longitude, item.project.latitude] : null"
                color="red"
              />

            </MglMap>

          </div>
        </ais-hits>
      </b-tab>
      <b-tab>

        <template
          #title
        >
          <feather-icon icon="MapIcon" />
          <span>Map</span>
        </template>
        <ais-autocomplete>
          <template #default="{ indices }">
            <!-- <div>
              {{ Object.keys(indices[0].results._rawResults[0].facets) }}
              {{ indices[0].results._rawResults[0].facets['project.geohash.hash8'] }}
            </div> -->
            <FilteredMap
              :active="activeTab !== 0"
              :clusters="facetsToClusters(indices[0].results._rawResults[0].facets)"
            />

          </template>
        </ais-autocomplete>

      </b-tab>

    </b-tabs>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left-inventory">
      <inventory-left-filter-sidebar
        :client="searchClient"
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

// ToDO: Remove all the filter options that came with the component library.

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import {
  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BAvatar, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BTabs, BTab, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import algoliasearch from 'algoliasearch/lite'
import { mapActions, mapGetters } from 'vuex'
import { createWidgetMixin } from 'vue-instantsearch'
import Mapbox from 'mapbox-gl'
import {
  MglMap, MglMarker, MglNavigationControl, MglGeolocateControl,
  MglFullscreenControl, MglScaleControl,
} from 'vue-mapbox'
import { connectGeoSearch } from 'instantsearch.js/es/connectors'
import { VueAutosuggest } from 'vue-autosuggest'
import ngeohash from 'ngeohash'

import InventoryLeftFilterSidebar from './InventorySearchLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useInventorySearch'
import { useInventoryUi } from '../useInventory'
import inventorySearchBar from './InventorySearchBar.vue'
import FilteredMap from '../map/FilteredMap.vue'
import 'instantsearch.css/themes/satellite-min.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BTabs,
    BTab,
    BFormGroup,
    VueAutosuggest,
    BAvatar,
    MglMap,
    MglMarker,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl,

    // SFC
    InventoryLeftFilterSidebar,
    inventorySearchBar,
    FilteredMap,
  },
  mixins: [createWidgetMixin({ connector: connectGeoSearch })],
  setup() {
    const algoliaIndexName = process.env.VUE_APP_PLATFORM_ALGOLIA_INDEX_PRODUCT_NAME

    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useInventoryUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
        .then(response => {
          products.value = response.data.products
          totalProducts.value = response.data.total
        })
    }

    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      algoliaIndexName,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  data() {
    return {
      accessToken: 'pk.eyJ1IjoiYXhzaiIsImEiOiJja3N6anp1NTMwOWZ1MnhzNWFyNW13MDduIn0.5xohA-1pZu3eCPbIBsPPdA',
      mapStyle: 'mapbox://styles/mapbox/streets-v11',

      searchClient: algoliasearch(

        process.env.VUE_APP_PLATFORM_ALGOLIA_CREDENTIALS_APP_ID,

        process.env.VUE_APP_PLATFORM_ALGOLIA_CREDENTIALS_ADMIN_API_KEY,
      ),
      hitsPerPageOption: {
        label: undefined,
        value: 9,
      },
      zoom: 3,
      theTotalProducts: undefined,
      page: 1,
      refineFunction: undefined,
      lotImages: ['../../../assets/images/lots/Lot1.jpg', '../../../assets/images/lots/Lot2.jpg', '../../../assets/images/lots/Lot3.jpg', '../../../assets/images/lots/Lot4.jpg', '../../../assets/images/lots/Lot5.jpg', '../../../assets/images/lots/Lot6.jpg'],
      activeTab: null,
    }
  },

  computed: {
    ...mapGetters('search', [
      'placeSearched',
    ]),
    widgetParams() {
      return {
        enableRefineOnMapMove: true,
      }
    },

    // ToDo: Mapp zoom out when no results found.
    initialCenter() {
      if (this.state.items[0]) {
        // eslint-disable-next-line no-underscore-dangle
        return this.state.items[0]._geoloc
      } return {
        lat: -24.71379,
        lng: 133.42316,
      }
    },
  },
  created() {
    this.mapbox = Mapbox
    window.InventorySearch = this
  },
  methods: {

    ...mapActions('search', [
      'updateProjectsSelected',
      'removeSearchRefinement',
    ]),
    clearRefinementForSerach() {
      this.state.clearMapRefinement()
      this.removeSearchRefinement()
    },
    selectedProjectRefinements(item) {
      this.updateProjectsSelected(item.label)
    },
    priceFormatter(price) {
      return price / 1000
    },
    async onMapLoad(event) {
      // Here we cathing 'load' map event
      // eslint-disable-next-line no-unused-vars
      const asyncActions = event.component.actions
      // console.log(event)
      // console.log(asyncActions.getBounds())

      // eslint-disable-next-line no-unused-vars
      // const newParams = await asyncActions.setCenter([133.42316, -24.71379])
    },
    // eslint-disable-next-line no-unused-vars
    async refineMap(state, event) {
      return null
    },
    clearRefinementForSliders(refinementsForItem, refine) {
      if (refinementsForItem.length > 1) {
        refine(refinementsForItem[0])
        refine(refinementsForItem[1])
      } refine(refinementsForItem[0])
    },
    clearRefinementForProject(project, refine) {
      refine(project)
    },

    displayRefinementsForPrice(item) {
      if (item.refinements.length > 1 && item.label === 'price') {
        // console.log(`Item: ${JSON.stringify(item)}`)
        return `${item.refinements[0].value / 1000}k - ${item.refinements[1].value / 1000}k`
      }
      return ` ${item.refinements[0].operator} ${item.refinements[0].value / 1000}k`
    },
    displayRefinementsForFrontageDepthAndSize(item) {
      if (item.refinements.length > 1 && (item.label === 'frontage' || item.label === 'depth' || item.label === 'size')) {
        // console.log(`Item: ${JSON.stringify(item)}`)
        return `${item.refinements[0].value}m - ${item.refinements[1].value}m`
      }
      return `${item.refinements[0].operator} ${item.refinements[0].value}m`
    },

    // log(value) {
    //   console.log(value)
    // },
    setTheTotalProducts(nbHts) {
      if (nbHts > 1000) {
        this.theTotalProducts = 1000
        return 1000
      }
      this.theTotalProducts = nbHts
      return nbHts
    },
    facetsToClusters(facets) {
      const geohashes = facets[Object.keys(facets).filter(f => f.match(/geohash/)).slice(-1)[0]]
      const clusters = Object.entries(geohashes).map(([geohash, count]) => {
        const ll = ngeohash.decode(geohash)
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [ll.longitude, ll.latitude],
          },
          properties: { count },
        }
      })
      return clusters
    },

  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>
$search-bar-height: 48px;
$spacer: 2;

.item-img-modified {
    padding-top: 0.5rem;
    min-height: 15.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
      }

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.mt-1 {
  margin-top: 1 !important;
}
.ml-1 {
  margin-left: ($spacer * 0.25) !important;
}

.mapboxgl-canvas {

  width: 100%;
}

  .item-description-modified {
        overflow: hidden;
        text-overflow: ellipsis;

  }

  .colour-change-img-white {
     filter: invert(59%) sepia(0%) saturate(1933%) hue-rotate(151deg) brightness(85%) contrast(95%);
  }

  .colour-change-img-black {
   filter: invert(0%) sepia(0%) saturate(15%) hue-rotate(280deg) brightness(101%) contrast(104%);
  }

</style>
 this.$store.state.appConfig.layout.skin ?
