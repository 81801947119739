<template lang="pug">
#MapPopup
  #MapPopup-body(v-show="lngLat && selectedLot" style="margin-bottom: 70px" :class="{ hide: hide, visible: !hide }" )
    div.bg-light.border.border-secondary.rounded-lg(:style="{ boxShadow: '4px 4px 12px 4px #555 ' }")
      #DimensionsMap(:style="{width:'330px', height:'280px' }")
      //- img(src="/map/dimensions-placeholder.png" :style="{ width:'100%', maxWidth: '330px' }")
      //- h3 Lot {{ p.DESCRIP }}
      .p-2(v-if="lngLat && selectedLot")
        .my-3
          .d-flex.justify-content-between
            .mr-3(:style="{ color:'black', fontWeight:'medium', fontSize: '30px' }") Serenity
            div(:style="{ color: 'hsl(180, 45%,50%)', fontSize:'30px' }") ${{ p.price.toLocaleString() }}
          .my-1(:style="{ fontSize:'20px' }") Rockbank
        .d-flex.justify-content-between.my-3
          div.mr-3(:style="{ color:'black', fontWeight:'medium',fontSize: '30px' }") LOT {{ p.DESCRIP }}
          .font-weight-bold(:style="{ fontSize:'20px' }") {{ p.AREA }} m<sup>2</sup>
        //- .mx-auto
        b-button(style="width:100%" variant="relief-secondary") Reserve
    #tail(:style="{ width:'5px', height:'5px', borderTop: '20px solid rgb(246,246,246)', borderRight: '20px solid transparent', borderLeft: '20px solid transparent', marginLeft:'50%', marginTop: '-1px' }")

</template>

<script>
import mapboxgl from 'mapbox-gl'
import glUtils from 'map-gl-utils'
import { BButton } from 'bootstrap-vue'
import * as turf from '@turf/turf'

export default {
  name: 'MapPopup',
  components: { BButton },
  props: {
    map: { type: Object, default: null },
    lngLat: { type: Array, default: null },
    selectedLot: { type: Object, default: null },
  },
  // props: [{ name: 'map', type: 'object' }, 'lngLat', 'selectedLot'],
  data: () => ({
    marker: null,
    hide: null,
  }),
  computed: {
    p() {
      return this.selectedLot?.properties
    },
  },
  watch: {
    async lngLat(lngLat, oldLngLat) {
      this.hide = true
      if (this.lngLat && this.map) {
        window.setTimeout(() => {
          this.marker
            .setLngLat(this.lngLat)
          if (!oldLngLat) {
            this.marker.addTo(this.map)
          }
          if (!this.dimensionsMap) {
            this.initDimensionsMap()
          }
          this.hide = false
        }, 500)
      }
    },
    async selectedLot() {
      if (this.selectedLot) {
        this.dimensionsMap.resize()
        this.updateDimensionsMap()
      }
    },
  },

  async mounted() {
    window.MapPopup = this
    this.initMarker()
  },
  methods: {
    initMarker() {
      this.marker = new mapboxgl.Marker({ element: document.getElementById('MapPopup'), anchor: 'bottom' })
    },
    async initDimensionsMap() {
      mapboxgl.accessToken = 'pk.eyJ1Ijoic3RldmFnZSIsImEiOiJja3VmYnpsOTMxdDRlMnVxbHNoYXV3Yjl0In0.TRPsdvEPJUBYymhyrvtyNw'
      this.dimensionsMap = new mapboxgl.Map({
        container: 'DimensionsMap',
        center: this.lngLat,
        zoom: 17,
        style: {
          version: 8,
          layers: [],
          sources: {},
          glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        },
        interactive: false,
      })
      window.dmap = this.dimensionsMap
      glUtils.init(this.dimensionsMap)

      await this.dimensionsMap.U.onLoad()
      this.dimensionsMap.addLayer({ type: 'background', id: 'background', 'background-color': '#777' })
      this.dimensionsMap.U.addGeoJSON('dimensions')
      this.dimensionsMap.U.addLine('dimensions-line', 'dimensions', { lineWidth: 3, lineColor: 'white' })
      this.dimensionsMap.U.addGeoJSON('sides')
      this.dimensionsMap.U.addSymbol('sides-label', 'sides', {
        textFont: ['DIN Offc Pro Regular'],
        textColor: 'white',
        textField: '{length}',
        textAllowOverlap: true,
        textIgnorePlacement: true,
        symbolPlacement: 'line-center',
        textOffset: [0, -0.7],
      })
      this.dimensionsMap.U.addSymbol('dimensions-center', 'dimensions', {
        textFont: ['DIN Offc Pro Bold'],
        textColor: 'white',
        textSize: 24,
        // textField: '{AREA} m²',
        textField: '{AREA}',
        textAllowOverlap: true,
        textIgnorePlacement: true,

      })
      this.dimensionsMap.U.addCircle('dimensions-circle', 'dimensions', {
        circleColor: 'white',
        circleRadius: 3,
      })
      this.updateDimensionsMap()
    },
    updateSideDimensions() {
      let prevPoint
      const sides = []
      // eslint-disable-next-line no-restricted-syntax
      for (const curPoint of this.selectedLot.geometry.coordinates[0]) {
        if (prevPoint) {
          const side = turf.lineString([prevPoint, curPoint])
          side.properties.length = Math.round(turf.length(side, { units: 'meters' }) * 10) / 10
          sides.push(side)
        }
        prevPoint = curPoint
      }
      const f = turf.featureCollection(sides)
      this.dimensionsMap.U.setData('sides', f)
    },
    updateDimensionsMap() {
      if (!this.dimensionsMap) {
        this.initDimensionsMap()
      }
      if (!this.selectedLot) {
        return
      }
      this.dimensionsMap.U.setData('dimensions', {
        type: 'Feature',
        properties: this.selectedLot.properties,
        geometry: this.selectedLot.geometry,
      })
      this.updateSideDimensions()
      this.dimensionsMap.fitBounds(turf.bbox(this.selectedLot.geometry), { padding: 30, animate: false })
    },
  },
}
</script>

<style>
#MapPopup .mapboxgl-ctrl-bottom-left {
  /* Suppress Mapbox logo. Need to double check licence conditions when using library without basemap. Or use MapLibre-GL-JS */
  display:none !important;
}

#MapPopup-body {
  transition:  opacity 500ms linear;
  opacity: 0;
}

#MapPopup-body.visible {
  /* transition: opacity 1500ms linear; */
  opacity: 1;
}

#MapPopup-body.hide {
  /* transition:  opacity 500ms linear; */
  opacity: 0;
}

</style>
