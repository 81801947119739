<template lang="pug">
b-form-checkbox-group(label="Layers" stacked :options="layers" v-model="selectedLayers" )
</template>

<script>
import { BFormCheckboxGroup, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'LayerSelector',
  components: { BFormCheckboxGroup, BFormCheckbox },
  data() {
    const layers = [{
      value: 'psp',
      text: 'Precinct structure plans',
    }, {
      value: 'landuse',
      text: 'Land use',
    },
    {
      value: 'terrain',
      text: 'Terrain',
    }]

    return {
      layers,
      selectedLayers: [],
    }
  },
  watch: {
    selectedLayers() {
      this.$emit('change', this.selectedLayers)
    },

  },
  created() {
    window.LayerSelector = this
  },

}
</script>

<style scoped></style>
