export default function initTerrain(map, { enabled } = {}) {
  if (!map.getSource('terrain')) {
    map.addSource('terrain', {
      type: 'raster-dem',
      url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
      tileSize: 512,
      maxzoom: 14,
    })
  }
  if (enabled) {
    map.setTerrain({
      source: 'terrain',
      exaggeration: 2,
    })
  } else {
    map.setTerrain()
  }

  if (!map.getSource('satellite')) {
    map.U.addRasterSource('satellite', {
      url:
      'mapbox://mapbox.satellite',
    })
  }
  map.U.addRasterLayer('satellite', 'satellite', {
    visibility: enabled ? 'visible' : 'none',
  })
  // hide road labels since they appear on top of all raster layers
  // https://github.com/mapbox/mapbox-gl-js/issues/11336
  map.U.toggle('road-label-simple', !enabled)
}
