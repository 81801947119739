<template lang="pug">
b-form-select#Basemap.mapboxgl-ctrl(v-model="basemap" :options="basemaps" style="width:10em")
</template>

<script>
import axios from 'axios'
import mapboxgl from 'mapbox-gl'
import { BFormSelect } from 'bootstrap-vue'

/*
  Switching basemap is more complex than it looks, because Mapbox GL JS doesn't distinguish between "basemap" and the other layers (like lots, stages etc) that get added over the top. So we have to load the new basemap, then merge back into it all the other layers that we had added.

  Source: https://github.com/mapbox/mapbox-gl-js/issues/4006#issuecomment-368273916
  styleID should be in the form "mapbox/satellite-v9"
*/
async function switchBasemap(map, styleID) {
  const { data: newStyle } = await axios.get(
    `https://api.mapbox.com/styles/v1/${styleID}?access_token=${mapboxgl.accessToken}`,
  )
  const currentStyle = map.getStyle()
  if (!currentStyle) {
    map.setStyle(newStyle)
    return
  }

  // ensure any sources from the current style are copied across to the new style
  newStyle.sources = {

    ...currentStyle.sources,
    ...newStyle.sources,
  }

  // find the index of where to insert our layers to retain in the new style
  let labelIndex = newStyle.layers.findIndex(el => el.id === 'waterway-label')

  // default to on top
  if (labelIndex === -1) {
    labelIndex = newStyle.layers.length
  }
  // app layers are the layers to retain, and these are any layers which have a different source set
  const appLayers = currentStyle.layers.filter(el => (
    el.source
        && el.source !== 'mapbox://mapbox.satellite'
        && el.source !== 'mapbox'
        && el.source !== 'composite'
  ))
  newStyle.layers = [
    ...newStyle.layers.slice(0, labelIndex),
    ...appLayers,
    ...newStyle.layers.slice(labelIndex, -1),
  ]
  map.setStyle(newStyle)
}

export default {
  name: 'Basemap',
  components: { BFormSelect },
  props: { map: { type: Object, default: null } },
  data() {
    const basemaps = [
      {
        text: 'Street map',
        value: 'stevage/ckuz5ix6y0i5j14o3py9cwn0a',
      },
      {
        text: 'Satellite',
        value: 'mapbox/satellite-streets-v11',
      },

    ]
    return {
      basemaps,
      basemap: basemaps[0].value,
    }
  },
  watch: {
    basemap(styleId) {
      switchBasemap(this.map, styleId)
    },
  },
  created() {
    window.BasemapSelector = this
  },

}

</script>

<style scoped></style>
