<template lang="pug">
    div(style="position:relative")
      #InventoryMap(style="width: 100%; height: 80vh")
      #controls.bg-white
        BasemapSelector(:map="map")
        LayerSelector.mt-2(@change="changeLayers")
      MapPopup(:map="map" :lngLat="popupLngLat" :selectedLot="selectedLot")
</template>

<script>
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev'
import glutils from 'map-gl-utils'
import * as turf from '@turf/turf'
import MapPopup from './MapPopup.vue'
import BasemapSelector from './BasemapSelector.vue'
import LayerSelector from './LayerSelector.vue'
import initLots from './layers/lots'
import initMasterplan from './layers/masterplan'
import initEstateFeatures from './layers/estateFeatures'
import initPsp from './layers/psp'
import initTerrain from './layers/terrain'
import initResults from './layers/results'

mapboxgl.accessToken = 'pk.eyJ1Ijoic3RldmFnZSIsImEiOiJja3VmYnpsOTMxdDRlMnVxbHNoYXV3Yjl0In0.TRPsdvEPJUBYymhyrvtyNw'
export default {
  name: 'InventoryMap',
  components: {
    MapPopup,
    BasemapSelector,
    LayerSelector,
  },
  props: {
    filters: { type: Object, default: null }, active: { type: Boolean }, items: { type: Array, default: null }, clusters: { type: Array, default: null },
  },
  data: () => ({
    map: null, popupLngLat: null, selectedLot: null, terrainEnabled: false,
  }),
  watch: {
    filters: {
      deep: true,
      handler() {
        initLots(this.map, { filters: this.filters })
      },
    },
    active() {
      if (this.map) {
        this.map.resize()
      }
    },
    clusters: {
      deep: true,
      handler() {
        if (this.map.getSource('results')) {
          this.map.U.setData('results', { type: 'FeatureCollection', features: this.clusters })
        }
      },
    },

  },
  created() {
    window.InventoryMap = this
  },
  async mounted() {
    this.map = new mapboxgl.Map({
      container: 'InventoryMap',
      style: 'mapbox://styles/stevage/ckuz5ix6y0i5j14o3py9cwn0a',
      center: [144.65432917651498, -37.738320055949885],
      minZoom: 7,
      maxZoom: 19,
      zoom: 17.5, // single lot
    })
    this.updatePitch()
    this.map.dragRotate.disable()
    this.map.boxZoom.disable()

    window.map = this.map
    glutils.init(this.map, mapboxgl)
    await this.map.U.onLoad()
    this.map.on('click', () => {
      this.selectedLot = null
      this.popupLngLat = null
    })
    this.map.on('zoom', e => {
      if (e.source !== 'flyTo') {
        this.updatePitch()
      }
    })
    initTerrain(this.map, { enabled: this.terrainEnabled })
    initMasterplan(this.map)
    initLots(this.map, { lotSelectedCb: this.selectLot.bind(this) })
    initEstateFeatures(this.map)
    initPsp(this.map)
    // initEstates(this.map)
    initResults(this.map)

    this.initMap()
  },
  methods: {
    initMap() {
      this.popupLngLat = [144.653, -37.738]
    },
    selectLot(feature) {
      this.popupLngLat = turf.centroid(feature).geometry.coordinates
      this.selectedLot = feature
      this.map.flyTo(
        {
          center: this.popupLngLat,
          zoom: 19,
          padding: { top: 350 },
          duration: 2000,
          pitch: 45,
          bearing: 0,
        },
        { source: 'flyTo' },
      )
    },
    updatePitch({ animate } = {}) {
      const z = Math.min(Math.max(this.map.getZoom() - 15, 0), 2) / 2
      const pitch = z * (this.terrainEnabled ? 60 : 45)

      if (animate) {
        this.map.easeTo({ pitch })
      } else {
      // sneaky way of injecting updating the pitch without interrupting the current zoom easing
        this.map.transform.pitch = pitch
      }
    },
    changeLayers(layers) {
      this.map.U.toggleSource('psp', layers.includes('psp'))
      this.map.U.toggleSource('landuse', layers.includes('landuse'))
      this.terrainEnabled = layers.includes('terrain')
      initTerrain(this.map, { enabled: this.terrainEnabled })
      this.updatePitch({ animate: true })
    },
  },
}
</script>

<style scoped lang="scss">
#controls {
    position: absolute;
    top:0;
    margin-top: 20px;
    margin-left: 10px;
    padding: 10px;
    border-radius: 5px;
}
</style>
