import * as turf from '@turf/turf'
import loadLotsData from './lotsData'

const lotColors = {
  available: {
    lineColor: 'hsl(140,46%,60%)',
    fillColor: 'hsl(140,60%,80%)',
    selectedFillColor: 'hsl(140,70%,40%)',
    hoverLineColor: 'hsl(140,46%,30%)',
  },
  availableUnmatching: {
    lineColor: 'hsl(140,26%,90%)',
    fillColor: 'hsl(140,60%,90%)',
    selectedFillColor: 'hsl(140,70%,40%)',
    hoverLineColor: 'hsl(140,46%,30%)',
  },
  sold: {
    lineColor: 'hsl(20,20%,80%)',
    fillColor: 'hsl(20,20%,80%)',
  },
  reserved: {
    lineColor: 'hsl(200,0%,80%)',
    fillColor: 'hsl(200,0%,80%)',
  },
  default: {
    lineColor: 'hsl(200,0%,90%)',
    fillColor: 'transparent',
  },
}

async function addLots(map) {
  map.U.addLine('lotsLineHit', 'lots', {
    lineColor: 'transparent',
    lineWidth: 4,
    filter: ['==', 'CODE', 'LOT'],
  })

  map.U.addFill('lotsFill', 'lots', {
    // fillColor: ['match', ['get', 'status'], ...Object.entries(lotColors).flatMap(([status, { fillColor }]) => [status, fillColor]), lotColors.default.fillColor],
    fillColor: ['match', ['feature-state', 'status'], ...Object.entries(lotColors).flatMap(([status, { fillColor }]) => [status, fillColor]), lotColors.default.fillColor],
    fillOpacity: 1,
    filter: ['==', 'CODE', 'LOT'],
  })
  const lotsLineColor = ['match', ['get', 'status'], ...Object.entries(lotColors).flatMap(([status, { lineColor }]) => [status, lineColor]), lotColors.default.lineColor]
  map.U.addLine('lotsLine', 'lots', {
    // lineColor: 'hsl(200,80%,80%)',
    lineColor: lotsLineColor,
    lineWidth: ['interpolate', ['linear'], ['zoom'], 15, 1, 18, 2],
    lineSortKey: ['match', ['get', 'status'], 'available', 3, 'reserved', 2, 'sold', 1, 0],
    filter: ['==', 'CODE', 'LOT'],

  })
  map.U.addLine('lotsLineHover', 'lots', {
    lineColor: [
      'case',
      ['to-boolean', ['feature-state', 'hover']],
      ['match', ['get', 'status'], ...Object.entries(lotColors).flatMap(([status, { lineColor, hoverLineColor }]) => [status, hoverLineColor || lineColor]), lotColors.default.hoverLineColor || lotColors.default.lineColor],
      'transparent',
    ],
    lineWidth: 4,
    filter: ['==', 'CODE', 'LOT'],
  })

  map.U.addSymbol('lotsIcon', 'lots', {
    iconImage: ['get', 'builder'],
    iconSize: ['interpolate', ['linear'], ['zoom'], 17, 0.1, 19, 0.4, 21, 0.6],
    iconPitchAlignment: 'map',
    iconRotationAlignment: 'map',
    minzoom: 17,
  })
}

async function addStages(map, lotsPromise) {
  const maxzoom = 18
  map.U.addFillExtrusion('stages', 'lots', {
    fillExtrusionColor: ['match', ['get', 'GROUP'],
      'STAGE 1', 'hsl(20, 80%, 70%)',
      // 'STAGE 2', 'hsl(100, 80%, 70%)',

      // 'STAGE 2', 'transparent',
      'STAGE 2', 'hsl(220, 10%, 70%)',
      'STAGE 3', 'hsl(220, 10%, 70%)',
      'STAGE 4', 'hsl(220, 10%, 70%)',
      'black',
    ],
    fillExtrusionOpacity: ['interpolate', ['linear'], ['zoom'], maxzoom - 1, 0.9, maxzoom, 0], // 0.9, // 0.5,
    fillExtrusionHeight: 6,
    fillExtrusionBase: 5,
    filter: ['==', 'CODE', 'LOT'],
    maxzoom,
    minzoom: 13,
    // visibility: 'none',
  })
  map.U.addGeoJSON('stageLabels')

  map.U.addGeoJSON('stageBoundary')
  map.U.addLine('stageBoundaryLine', 'stageBoundary', {
    lineColor: 'black',
    lineWidth: 3,
    visibility: 'none',
  })

  const lots = await lotsPromise
  const stageCollections = '1 2 3 4'.split(' ').map(stageNo => turf.featureCollection(lots.features.filter(f => f.properties.GROUP === `STAGE ${stageNo}`)))
  const stageCentroids = stageCollections.map((stageFC, i) => turf.centroid(stageFC, { properties: { stageNo: `Stage ${i + 1}` } }))
  const stageBoundaries = turf.dissolve(lots, { propertyName: 'GROUP' })
  map.U.setData('stageLabels', turf.featureCollection(stageCentroids))
  map.U.setData('stageBoundary', stageBoundaries)
}

function addStageLabels(map) {
  map.U.addSymbol('stageLabels', 'stageLabels', {
    textField: ['get', 'stageNo'],
    textHaloColor: 'hsla(0,0%,100%,0.5)',
    textHaloWidth: 2,
    textSize: ['interpolate', ['linear'], ['zoom'], 15, 16, 18, 30],
    maxzoom: 18,
    minzoom: 15,

  })
}

async function updateFilters(map, lotsDataPromise, filters) {
  function matchesFilter(p) {
    if (filters.price && !(p.price >= filters.price[0] && p.price <= filters.price[1])) {
      return false
    }
    if (filters.frontage && !(p.frontage >= filters.frontage[0] && p.frontage <= filters.frontage[1])) {
      return false
    }
    return true
  }
  const lotsData = await lotsDataPromise
  for (const feature of lotsData.features) {
    const matching = matchesFilter(feature.properties)
    let { status } = feature.properties
    if (status === 'available' && !matching) {
      status = 'availableUnmatching'
    }

    map.setFeatureState({
      id: feature.id,
      source: 'lots',
    }, {
      matching,
      status,
    })
  }
}
let lotsDataPromise
// eslint-disable-next-line func-names
export default function (map, { lotSelectedCb, filters = {} }) {
  if (!map.getSource('lots')) {
    // map.U.addVector('lots', cadApi.lotsTiles())
    map.U.addGeoJSON('lots', null)
    lotsDataPromise = loadLotsData(map)
    lotsDataPromise.then(lotsData => {
      map.U.setData('lots', lotsData)
      updateFilters(map, lotsDataPromise, filters)
    })
    addStages(map, lotsDataPromise)
    addLots(map)
    addStageLabels(map)
    for (const icon of ['metricon', 'simonds', 'urbanedge']) {
      map.U.loadImage(icon, `/map/builder-logos/${icon}.png`)
    }

    let selectedLot = null
    map.U.addFillExtrusion('lotsSelected', 'lots', {
      fillExtrusionHeight: 5,
      fillExtrusionColor: lotColors.available.selectedFillColor,
      fillExtrusionOpacity: 0.5,
      filter: ['==', 'DESCRIP', '146'],
    })
    map.setPaintProperty('lotsSelected', 'fill-extrusion-height-transition', {
      duration: 500,
      delay: 500,
    })

    let activeFeature
    map.U.hoverPointer('lotsFill')
    map.on('mousemove', 'lotsFill', e => {
      const f = e.features[0]
      if (f.id !== activeFeature) {
        if (activeFeature !== undefined) {
          map.setFeatureState(
            { id: activeFeature, source: 'lots' },
            { hover: false },
          )
        }
        if (f.properties.status !== 'available') {
          return
        }
        map.setFeatureState({ id: f.id, source: 'lots' }, { hover: true })
        activeFeature = f.id
      }
    })
    map.on('mouseleave', 'lotsFill', () => {
      if (activeFeature !== undefined) {
        map.setFeatureState(
          { id: activeFeature, source: 'lots' },
          { hover: false },
        )
      }
    })
    // map.U.hoverFeatureState('lotsFill')
    map.on('click', 'lotsFill', e => {
      const f = e.features[0]
      if (f.properties.status !== 'available') {
        map.U.setFillExtrusionHeight('lotsSelected', 0)
        map.U.setFilter('lotsSelected', false)
        return
      }

      lotSelectedCb?.(e.features[0])
      selectedLot = e.features[0].properties.DESCRIP
      map.U.setFillExtrusionHeight('lotsSelected', 0)
      map.U.setFilter('lotsSelected', ['==', 'DESCRIP', selectedLot])
      map.setPaintProperty('lotsSelected', 'fill-extrusion-height-transition', {
        duration: 0,
        delay: 0,
      })

      window.setTimeout(() => {
        map.setPaintProperty('lotsSelected', 'fill-extrusion-height-transition', {
          duration: 500,
          delay: 0,
        })

        map.U.setFillExtrusionHeight('lotsSelected', 10)
      }, 100)
    })
  }
  // if (filters.price) {
  //   const [minPrice, maxPrice] = filters.price
  //   map.U.setFilter(['lotsFill', 'lotsLine'], ['all', ['>=', ['get', 'price'], minPrice], ['<=', ['get', 'price'], maxPrice]])
  // }
  if (filters) {
    updateFilters(map, lotsDataPromise, filters)
  }

  // EventBus.$on('lots-imported', () => {
  //   map.getSource('lots').setTiles([cadApi.lotsTiles()])
  // })
}
