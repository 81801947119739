/*

Greenfield Precinct Structure Plans

See https://planvic.maps.arcgis.com/apps/webappviewer/index.html?id=536f9e451be0496c89548ae04013d76b
Source: https://data-planvic.opendata.arcgis.com/datasets/6fa6b6898e8a41fbbc8a189895e67b1c_0/explore

*/

export default async function init(map) {
  map.U.addVector('psp', 'mapbox://stevage.25801l1z/?version=1')
  map.U.addFill('psp-fill', 'psp', {
    sourceLayer: 'psp-b0pbbs',
    fillOutlineColor: 'hsl(150,50%,40%)',
    fillColor: [
      'match',
      ['get', 'STATUS_DESC'],
      'Completed',
      'hsl(150,100%,20%)',
      'Under Preparation',
      'hsl(150,50%,40%)',
      'Pre-Commencement',
      'hsl(150,40%,70%)',
      'Unprogrammed',
      'hsl(150,20%,80%)',
      'grey',
    ],
    fillOpacity: 0.5,
    visibility: 'none',
  })
  //   map.U.addSymbol('psp-label',

  // Landuse plans. There are lots more types of features than we're showing here.
  // from https://services5.arcgis.com/DmRfik4clMVydXO3/arcgis/rest/services/FUS_LandUse_ArcGISUpload/FeatureServer/0

  map.U.addVector('landuse', 'mapbox://stevage.8999iajt')
  map.U.addFill('landuse-fill', 'landuse', {
    sourceLayer: 'landuse-410d8x',
    fillColor: [
      'match',
      ['get', 'LU_TYPE'],
      ['Existing Open Space', 'Local Open Space'],
      'hsl(120,80%,50%)',
      ['Town Centre', 'Community Facilities'],
      'yellow',
      ['Education'],
      'hsl(60,80%,50%)',
      ['Conservation'],
      'hsl(120,50%,60%)',
      'transparent',
    ],
    fillOpacity: 0.5,
    visibility: 'none',
  })
}
