// eslint-disable-next-line func-names
export default function (map) {
  if (!map.getSource('masterplan')) {
    map.U.addImageSource('masterplan', {
      url: '/map/serenity-no-text.jpg',
      coordinates: [
        [144.6506354655108, -37.73597914461266],
        [144.65908897491335, -37.73684615484079],
        [144.65837421537725, -37.74114705546011],
        [144.6499446311813, -37.74034237667289],
      ],
    })
  }
  map.U.addRasterLayer('masterplan-raster', 'masterplan', {
    rasterOpacity: 1,
    minzoom: 15,
  })
  // map.moveLayer('masterplan-raster', 'gl-draw-polygon-fill-inactive.cold');
}
