export default async function addEstateFeatures(map) {
  map.U.addGeoJSON('estateFeatures')
  map.U.addSymbol('estateFeatures-streetLabels', 'estateFeatures', {
    textField: ['get', 'name'],
    textColor: 'white',
    symbolPlacement: 'line-center',
    filter: ['==', ['get', 'type'], 'street'],

  })
  const estateFeatures = await (await fetch('/map/serenity-estate-features.geojson')).json()
  map.U.setData('estateFeatures', estateFeatures)
}
