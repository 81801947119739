import * as turf from '@turf/turf'

window.turf = turf
function offset(feature, lngOffs, latOffs) {
  function offsetCoords(coords) {
    if (Array.isArray(coords[0])) {
      return coords.map(offsetCoords)
    }
    return [coords[0] + lngOffs, coords[1] + latOffs]
  }
  if (feature.type === 'FeatureCollection') {
    return {
      ...feature,
      features: feature.features.map(f => offset(f, lngOffs, latOffs)),
    }
  }
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: offsetCoords(feature.geometry.coordinates),
    },
  }
}

// eslint-disable-next-line func-names
window.adjust = function (x, y) {
  window.map.U.setData('lots', offset(window.g, x, y))
}

window.offset = offset

function randomSeedParkMiller(seed = 123456) {
  // https://gist.github.com/blixt/f17b47c62508be59987b
  let theSeed = seed
  theSeed %= 2147483647
  return () => {
    theSeed = (theSeed * 16807) % 2147483647
    return (theSeed - 1) / 2147483646
  }
}

const random = randomSeedParkMiller()

function makeLotAttributes() {
  const lotAttributes = {}
  for (let i = 201; i <= 299; i++) {
    const r = random() * 10

    let status
    if (r < 3) {
      status = 'sold'
    } else if (r < 6) {
      status = 'reserved'
    } else {
      status = 'available'
    }
    lotAttributes[i] = { status }

    if (lotAttributes[i].status === 'reserved') {
      lotAttributes[i].builder = ['metricon', 'simonds', 'urbanedge'][Math.floor(random() * 3)]
    }
  }
  return lotAttributes
}

function calculateFrontage(feature) {
  /*
  Here's our bodgy formula:
  Take the area
  Assume our shape is a rectangle in proportions 2:1
  Area = (2w) * w
  Area = 2ww
  w = sqrt(area/2)
  */
  return Math.sqrt(turf.area(feature, { units: 'meters' }) / 2)
}

export default async function loadData() {
  const features = await (await fetch('/map/serenity.geojson')).json()
  window.g = features
  // Data is in the correct projection but strangely mislocated by a kilometre or two.
  const featuresTransformed = offset(features, 0.02326, 0.01755)

  const lotAttributes = makeLotAttributes()
  let id = 1
  for (const feature of featuresTransformed.features) {
    feature.id = id++
    const p = feature.properties
    Object.assign(p, lotAttributes[+feature.properties.DESCRIP])
    p.calcArea = turf.area(feature)
    p.price = (Math.round(p.calcArea * 1.1) * 1000)

    p.frontage = calculateFrontage(feature)
  }
  // console.log(featuresTransformed)

  return featuresTransformed
}
