export default function initResults(map) {
  if (!map.getSource('results')) {
    // map.U.addGeoJSON('results', { cluster: true })
    map.addSource('results', {

      type: 'geojson',
      cluster: true,
      clusterRadius: 30,
      clusterProperties: {
        count: ['+', ['get', 'count']],

      },
    })
  }
  map.U.addCircleLayer('results-circle', 'results', {
    circleColor: 'hsl(180,60%,50%)',
    circleStrokeColor: 'white',
    circleStrokeWidth: 2,
    circleRadius: 20,
  })
  map.U.addSymbolLayer('results-count', 'results', {
    textField: ['get', 'count'],
    textColor: 'white',
    textAllowOverlap: true,
    type: 'raster-dem',
    textFont: ['DIN Pro Bold'],
  })
}
